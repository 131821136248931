//Authenticate
export const apiLogin = `/chuyendoiso/api/v1/Login`
export const apiLogout = `/chuyendoiso/api/v1/Logout`
export const apiRegister = `/chuyendoiso/api/v1/CreateUser`
export const apiGetCategoriesIncludeRoles = `/chuyendoiso/api/v1/GetCategoriesIncludeRoles`
export const apiGetUserToRole = `/chuyendoiso/api/v1/GetUserToRole`
export const apiCreateRoleGroup = `/chuyendoiso/api/v1/CreateRoleGroup`
export const apiUpdateRoleGroup = `/chuyendoiso/api/v1/UpdateRoleGroup`
export const apiGetPermissionGroup = `/chuyendoiso/api/v1/GetPermissionGroup`

export const apiRegisterAccount = `Authservice/RegisterAccount`
export const apiForgotPassword = `Authservice/ForgotPassword`
export const apiChangePassword = `Authservice/ChangePassword`
export const apiVerifyCode = `Authservice/VerifyCode`
