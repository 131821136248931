import {
  apiCreateContribute,
  apiUploadFiles,
} from "./urls"
import http from "../index"
import QueryString from "qs"

// const getContributes = body => http.get(apiGetContributes + `?${QueryString.stringify(body)}`)
// const getContributeDetail = body => http.post(apiGetContributeDetail + `?${QueryString.stringify(body)}`)
// const UpdateContributeStatus = body => http.post(apiUpdateContributeStatus, body)

const CreateContribute = body => http.post(apiCreateContribute, body)
const UploadFilesContribute = body => http.post(apiUploadFiles, body)


const ContributeServiceUser = { CreateContribute,UploadFilesContribute}
export default ContributeServiceUser
