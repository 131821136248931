import { Image, Modal, Space } from "antd"
import React, { useEffect, useState } from "react"
import Button from "src/components/MyButton/Button"

const ModalDetails = ({ open, onOk, onCancel, getData }) => {
  const [url, setUrl] = useState("")
  const [fileExtension, setFileExtension] = useState("")
  const urlLink = `https://docs.google.com/viewer?url=${open?.File?.url}&embedded=true`

  useEffect(() => {
    if (open?.File?.url) {
      const extension = open.File.url.split(".").pop().toLowerCase()
      setFileExtension(extension)
      setUrl(open.File.url)
    }
  }, [open])

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"]

  const isImage = imageExtensions.includes(fileExtension)

  return (
    <div>
      <Modal
        title="Xem tài liệu"
        open={!!open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              onClick={() => {
                onCancel()
              }}
            >
              Thoát
            </Button>
          </Space>
        }
      >
        {isImage ? (
          <Image width={200} src={open.File.url} />
        ) : (
          <iframe
            src={urlLink}
            title="title"
            style={{ width: "90%", height: "90vh" }}
          ></iframe>
        )}
      </Modal>
    </div>
  )
}

export default ModalDetails
