import React from 'react';
import { StyleButoonCustom } from '../../../styled';
import { RightOutlined } from '@ant-design/icons';
import Button from 'src/components/MyButton/Button';
import SvgIcon from 'src/components/SvgIcon';

const ButoonCustomStyle = ({ backgroundColor, color, text, icon }) => {
    return (
        <StyleButoonCustom>

            <button className="button" style={{ backgroundColor, color }}>
                {text}
                {icon && <span className="icon">{icon}</span>}
            </button>
        </StyleButoonCustom>

    );
}

export default ButoonCustomStyle;
