import {
  apiCreateOrUpdateRole,
  apiGetAllForCombobox,
  apiGetByRoleId,
  apiGetListRole,
  apiGetListTab,
  apiGetListTask,
  apiChangeStatus,
  apiDeleteRoleGroupByID
} from "./urls"
import http from "../index"
import QueryString from "qs"

const getListRole = (body) => http.get(apiGetListRole  + `?${QueryString.stringify(body)}`)
// const getListRole = (body) => http.get(`${apiGetListRole}?${QueryString.stringify(body)}`)

const getByRoleId = params => http.get(apiGetByRoleId, { params })
const createOrUpdateRole = body => http.post(apiCreateOrUpdateRole, body)
const getAllForCombobox = () => http.get(apiGetAllForCombobox)
const getListTab = () => http.get(apiGetListTab)
const getListTask = () => http.get(apiGetListTask)
const deleteRoleGroupByID = (body) => http.delete(apiDeleteRoleGroupByID + `?${QueryString.stringify(body)}`)
const changeStatus = params =>
  http.patch(apiChangeStatus + `?${QueryString.stringify(params)}`)
const RoleService = {
  getListRole,
  createOrUpdateRole,
  getByRoleId,
  getAllForCombobox,
  getListTab,
  deleteRoleGroupByID,
  getListTask,
  changeStatus,
}
export default RoleService
