export const dataSelec = [
  { value: "BM01", label: "BM 01 : Mẫu nội quy diễn tập thực chiến" },
  { value: "BM02", label: "BM 02 : Mẫu nội quy diễn tập thực chiến" },
]
export const dataEditor = [
  { text: "APPLE", value: "apple", url: "apple" },
  { text: "BANANA", value: "banana", url: "banana" },
  { text: "CHERRY", value: "cherry", url: "cherry" },
  { text: "DURIAN", value: "durian", url: "durian" },
  { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
  { text: "FIG", value: "fig", url: "fig" },
  { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
  { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
]
export const itemsCollapse = [
  {
    key: "1",
    label: "ĐIỀU 3: YÊU CẦU THỰC HIỆN ĐỐI VỚI CÁC ĐỘI THAM GIA DIỄN TẬP",
    children: (
      <p>
        1. Đối với Đội tấn công <br />
        - Tuân thủ nguyên tắc tấn công được quy định tại Điều 2, Khoản 3; <br />
        - Mọi thông tin liên quan đến danh tính của các đơn vị làm mục tiêu tấn
        công sẽ được bảo vệ theo chế độ mật;
        <br />
        - Cung cấp các bằng chứng liên quan chứng minh cho quá trình xâm nhập
        vào hệ thống mục tiêu;
        <br />
        - Phải báo cáo về BTC phương pháp, công cụ tương ứng với các bước đã
        thực hiện và kết quả của quá trình tấn công (bao gồm cả các điểm yếu
        nghiêm trọng và không nghiêm trọng);
        <br />
        - Ngay sau khi chiếm được quyền điều khiển hệ thống, Đội tấn công phải
        dừng cuộc tấn công và chuyển phương án tấn công mới (nếu có), cố gắng
        phát hiện tối đa các điểm yếu đang tồn tại trên hệ thống;
        <br />
        - Tất cả các công cụ, đoạn mã phục vụ cho tấn công phải được làm sạch
        trên hệ thống bị xâm nhập sau khi kết thúc diễn tập.
        <br />
        2. Đối với đội phòng thủ
        <br />
        - Tuân thủ nguyên tắc phòng thủ được quy định tại Điều 2, Khoản 2;
        <br />
        - Bố trí nhân sự phù hợp tham gia diễn tập; Đội phòng thủ có thể kết hợp
        với đơn vị đang cung cấp dịch vụ giám sát, bảo đảm an toàn, an ninh
        thông tin mạng cho tổ chức để tham gia diễn tập nhằm đánh giá năng lực
        ứng phó của đơn vị cung cấp;
        <br />- Báo cáo lại quá trình phát hiện và ngăn chặn, đưa ra các bằng
        chứng cụ thể về các hoạt động của Đội tấn công để làm cơ sở đánh giá
        năng lực của Đội Ứng cứu sự cố; rút ra bài học kinh nghiệm để cải thiện
        năng lực phòng thủ.
      </p>
    ),
  },
  {
    key: "2",
    label: "ĐIỀU 4: BÁO CÁO, TỔNG HỢP KẾT QUẢ DIỄN TẬP VÀ XẾP LOẠI CÁC ĐỘI",
    children: (
      <p>
        Các đội tấn công phải gửi báo cáo cho BTC gồm các nội dung: phương pháp,
        tên công cụ và kết quả của việc tấn công theo các quy tắc sau:
        <br />
        - Thời hạn: trong thời gian thực hiện diễn tập.
        <br />
        - Hình thức gửi:
        <br />
        + Gửi qua kênh email do BTC cung cấp (hoặc kênh khác do BTC quy định).
        <br />
        + Tệp báo cáo được bảo vệ an toàn bằng mã hóa (hoặc đặt mật khẩu).
        <br />
        + Trao đổi khóa giải mã hoặc mật khẩu bằng Pretty Good Privacy (PGP)
        hoặc phương thức trao đổi an toàn khác do BTC quy định.
        <br />
        1. Đội phòng thủ gửi báo cáo về BTC về các vấn đề phát hiện, theo dõi
        ngăn chặn trong quá trình bảo vệ hệ thống: về thời gian, chứng cứ, kỹ
        thuật tấn công...; thông tin báo cáo là cơ sở đánh giá năng lực của đội
        ứng cứu sự cố; rút ra những bài học kinh nghiệm để cải thiện năng lực
        phòng thủ.
        <br />
        2. BTC tiếp nhận, phản hồi việc nộp kết quả cho các Đội tấn công (hoặc
        Đội phòng thủ và Đội tấn công tùy thuộc tính chất cuộc diễn tập), đồng
        thời tổng hợp đánh giá, xếp loại các đội theo nguyên tắc có tính thời
        gian gửi kết quả.
        <br />
        3. BTC gửi báo cáo cho Ban giám khảo (BGK) để thực hiện đánh giá kết
        quả.
        <br />
        4. BGK đánh giá Đội tấn công, phòng thủ theo thang điểm cụ thể. BGK tổng
        hợp kết quả đánh giá và gửi cho BTC.
        <br />
      </p>
    ),
  },
  {
    key: "3",
    label: "ĐIỀU 5:TIÊU CHÍ ĐÁNH GIÁ NĂNG LỰC CỦA ĐỘI TẤN CÔNG",
    children: (
      <p>
        Các Đội tấn công được BGK đánh giá theo thang điểm 100 dựa trên:
        <br />
        - Sô lượng và mức độ nghiêm trọng của lỗ hổng, điểm yếu phát hiện được
        (tối đa 30/100 điểm);
        <br />
        - Mức độ phức tạp của kỹ thuật tấn công và công cụ sử dụng(tối đa 20/100
        điểm);
        <br />
        - Khuyến nghị hướng khắc phục (tối đã 25/100 điểm);
        <br />
        - Tính tuân thủ hoặc không tuân thủ Nội quy diễn tập (được cộng hoặc trừ
        tối đa 15/100 điểm);
        <br />
        - Thời gian gửi báo cáo (tối đa 5/100 điểm);
        <br />
        - Giải đáp các câu hỏi của BGK (tối đa 5/100 điểm);
        <br />
      </p>
    ),
  },
  {
    key: "4",
    label: "ĐIỀU 6: TIÊU CHÍ ĐÁNH GIÁ NĂNG LỰC CỦA ĐỘI ỨNG CỨU SỰ CỐ",
    children: (
      <p>
        BGK sẽ dựa trên kết quả báo cáo của các Đội tấn công và Đội phòng thủ để
        làm cơ sở đánh giá năng lực của Đội ứng cứu sự cố. BGK sẽ đánh giá theo
        thang điểm 100.
        <br />
        1. Đánh giá hiện trạng (công nghệ, quy trình, con người) (40/100 điểm)
        <br />
        - Có nhân sự đào tạo bài bản đúng chuyên môn về ATTT (tối đa 10 điểm)
        <br />
        - Xây dựng và tuân thủ quy trình phát triển, ngăn chặn, ứng cứu sự cố đã
        được ban hành (tối đa 15 điểm)
        <br />
        - Có trang bị các công nghệ, giải pháp theo dõi, phát hiện tấn công mạng
        (tối đa 15 điểm).
        <br />
        2. Năng lực phát hiện tấn công (30/100 điểm)
        <br />
        - Khả năng ghi nhận (thời gian và bằng chứng chi tiết) các hoạt động dò
        quét cổng, thăm dò quét cổng, thăm dò hệ thống được đội ngũ giám sát
        phát hiện và theo dõi trong quá trình diễn tập (tối đa 15 điểm).
        <br />
        - Khả năng ghi nhận và cảnh báo các tải trọng (payloads) liên quan đến
        hoạt động dò quét, khai thác lỗ hổng (tối đa 15 điểm).
        <br />
        - Khả năng phân tích tấn công để đưa ra dấu hiệu nhận diện, cập nhật lại
        hệ thống giám sát liên quan đến hành động dò quét, khai thác lỗ hổng và
        xâm nhập hệ thống (tối đa 10 điểm).
        <br />
        3. Khả năng ngăn chặn, ứng cứu sự cố tấn công mạng (30/100)
        <br />
        - Khả năng ngăn chặn dựa trên việc chặn IP kẻ tấn công (tối đa 10 điểm).
        <br />
        - Khả năng ngăn chặn dựa trên việc chặn payload liên quan đến hoạt động
        tấn công (tối đa 5 điểm).
        <br />
        - Các phương án dự phòng, ứng cứu sự cố được chuẩn bị sẵn trong trường
        hợp phải cô lập hệ thống đang vận hành (tối đa 15 điểm)
        <br />
      </p>
    ),
  },
]
