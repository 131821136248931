// Region
export const apiGetPractises = `/quytrinh/api/v1/GetListPractises`
export const apiCreatePractise = `/quytrinh/api/v1/CreatePractise`
export const apiGetPractisesByID = `/quytrinh/api/v1/GetPractisesByID`
// export const apiGetUserInPractise = `/quytrinh/api/v1/GetUserInPractise`
// export const apiAddUserInPractise = `/quytrinh/api/v1/AddUserInPractise`

export const apiUpdatePractiseByID = `/quytrinh/api/v1/UpdatePractiseByID`
export const apiDeletePractiseByID = `/quytrinh/api/v1/DeletePractiseByID`
export const apiAddUserInPractise = `/quytrinh/api/v1/AddUserInPractise`
export const apiGetUserInPractise = `/quytrinh/api/v1/GetUserInPractise`
export const apiAddNewFileInPractise = `/quytrinh/api/v1/AddNewFileInPractise`
export const apiGetAllFilesInPractise = `/quytrinh/api/v1/GetAllFilesInPractise`
export const apiGetFileByID = `/quytrinh/api/v1/GetFileByID`
export const apiUpdateFileByID = `/quytrinh/api/v1/UpdateFileByID`
export const apiDeleteFileByID = `/quytrinh/api/v1/DeleteFileByID`
export const apiRegisterToPractise = `/quytrinh/api/v1/RegisterToPractise`
export const apiGetRegisterInPractise = `/quytrinh/api/v1/GetRegisterInPractise`
export const apiApprovalOfRregistration = `/quytrinh/api/v1/ApprovalOfRregistration`
export const apiGetPositionInPractise = `/quytrinh/api/v1/GetPositionInPractise`
export const apiAddBannerInfo = `/quytrinh/api/v1/AddBannerInfo`
export const apiGetBannerInfo = `/quytrinh/api/v1/GetBannerInfo`
export const apiGetDeployPlan = `/quytrinh/api/v1/GetDeployPlan`
export const apiAddDeployPlan = `/quytrinh/api/v1/AddDeployPlan`
export const apiDeleteDeployPlan = `/quytrinh/api/v1/DeleteDeployPlan`
export const apiGetUserForPractise = `/quytrinh/api/v1/GetUserForPractise`
export const apiAddTeamInPractise = `/quytrinh/api/v1/AddTeamInPractise`
export const apiGetRankingOfPractise = `/quytrinh/api/v1/GetRankingOfPractise`
export const apiGetRoleInPractise = `/quytrinh/api/v1/GetRoleInPractise`
export const apiGetEvaluateOfPractise = `/quytrinh/api/v1/GetEvaluateOfPractise`
export const apiAddPointToTeam = `/quytrinh/api/v1/AddPointToTeam`
export const apiGetTeamInPractise = `/quytrinh/api/v1/GetTeamInPractise`
export const apiGetAllPractises = `/quytrinh/api/v1/GetAllPractises`
export const apiConfirmInviteOfPractise = `/quytrinh/api/v1/ConfirmInviteOfPractise`
export const apiSendNotiToAll = `/quytrinh/api/v1/SendNotiToAll`
export const apiMoveUsertoTeam = `/quytrinh/api/v1/MoveUsertoTeam`
export const apiAllowPublishResult = `/quytrinh/api/v1/AllowPublishResult`
export const apiGetPublishResult = `/quytrinh/api/v1/GetPublishResult`
export const apiAddToSchedule = `/quytrinh/api/v1/AddToSchedule`
export const apiRefuseInviteOfPractise = `/quytrinh/api/v1/RefuseInviteOfPractise`
export const apiImportUserByFileForInvite = `/quytrinh/api/v1/ImportUserByFileForInvite`
export const apiImportTeamPracByFile = `/quytrinh/api/v1/ImportTeamPracByFile`
export const apiGetNotiToAll = `/quytrinh/api/v1/GetNotiToAll`
export const apiDeleteTeamInPractise = `/quytrinh/api/v1/DeleteTeamInPractise`
export const apiEditTeamInPractise = `/quytrinh/api/v1/EditTeamInPractise`

export const apiUpdateRegion = `Region/Update`
export const apiDeleteRegion = `Region/Delete`
export const apiGetLocationVN = `Region/GetLocationVN`
export const apiGetByRegionId = `Region/GetByRegionId`

export const apiGetAllRegions = `/chuyendoiso/api/v1/GetAllRegions`
