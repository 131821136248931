import {
  apiGetForms,
  apiCreateFormBM02,
  apiCreateFormBM01,
  apiGetFormsProcedure,
  apiGetFormByID,
  apiCreateFormBM03,
  apiCreateFormBM04,
  apiGetListError,
  apiGetLevelError,
  apiReceiveForm,
  apiGetErrorForms,
  apiCreateFormBM05,
} from "./urls"
import http from "../index"
import QueryString from "qs"

const GetForms = body =>
  http.get(apiGetForms + `?${QueryString.stringify(body)}`)
const PostForms = body => http.post(apiCreateFormBM02, body)
const PostForm1 = body => http.post(apiCreateFormBM01, body)
const PostForm3 = body => http.post(apiCreateFormBM03, body)
const PostForm4 = body => http.post(apiCreateFormBM04, body)
const createFormBM05 = body => http.post(apiCreateFormBM05, body)

const receiveForm = body => http.post(apiReceiveForm, body)
const getListError = body =>
  http.get(apiGetListError + `?${QueryString.stringify(body)}`)
const getLevelError = body =>
  http.get(apiGetLevelError + `?${QueryString.stringify(body)}`)
const getFormsProcedure = body =>
  http.get(apiGetFormsProcedure + `?${QueryString.stringify(body)}`)
const getFormByID = body =>
  http.get(apiGetFormByID + `?${QueryString.stringify(body)}`)

const getErrorForms = params => http.get(apiGetErrorForms, { params })

const FormService = {
  createFormBM05,
  getErrorForms,
  receiveForm,
  getLevelError,
  getListError,
  PostForm4,
  PostForm3,
  GetForms,
  PostForms,
  PostForm1,
  getFormsProcedure,
  getFormByID,
}
export default FormService
