// Region
export const apiGetAllChidrenByRegionId = `Region/getAllChidrenByRegionId`
export const apiInsertRegion = `Region/Insert`
export const apiUpdateRegion = `Region/Update`
export const apiDeleteRegion = `Region/Delete`
export const apiGetLocationVN = `Region/GetLocationVN`
export const apiGetByRegionId = `Region/GetByRegionId`

export const apiGetAllRegions = `/chuyendoiso/api/v1/GetAllRegions`
export const apiGetListDistrict = `/chuyendoiso/api/v1/GetListDistrict`
export const apiGetListProvince = `/chuyendoiso/api/v1/GetListProvince`
export const apiGetListwards = `/chuyendoiso/api/v1/GetListwards`
