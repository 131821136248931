import { Col, Form, Input, Modal, Row, Select, Space, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import PractiseService from "src/services/PractiseService"

const TeamAdd = ({ open, onOk, onCancel, getData, listPractise }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [listMember, setListMember] = useState(false)
  const { id } = useParams()

  const getListMember = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getUserForPractise()
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.fullname,
          }
        })
        setListMember(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const createForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const requestBody = {
        ...values,
        practise_id: id,
      }
      console.log(values)
      const res = await PractiseService.addTeamInPractise(requestBody)
      if (res.isError) return
      if (res.isOk) {
        getData()
        onCancel()
        Notice({
          isSuccess: true,
          msg: "Thêm đội thành công",
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getListMember()
  }, [])

  return (
    <div>
      <Modal
        title="Thêm đội tham gia diễn tập"
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              onClick={() => createForm()}
              htmlType="submit"
            >
              Ghi lại
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          className="mt-20"
          scrollToFirstError
        >
          <Spin spinning={loading}>
            <Row gutter={[14, 10]}>
              <Col span={24}>
                <Form.Item
                  label="Vai trò"
                  name="team_type"
                  rules={[
                    {
                      required: true,
                      message: "Đội diễn tập không được để trống",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Nhập"
                    options={listPractise}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Tên Đội diễn tập" name="team_name">
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                <Form.Item label="Thành viên đội" name="user_id">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Nhập"
                    options={listMember}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Chức vụ"
                  name="position_id"
                  rules={[
                    {
                      required: true,
                      message: "Đội diễn tập không được để trống",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Nhập"
                    options={open}
                  />
                </Form.Item>
              </Col> */}
            </Row>
          </Spin>
        </Form>
      </Modal>
    </div>
  )
}

export default TeamAdd
