import { Col, Divider, Row, Select, Spin, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import FlSelect from "src/components/FloatingLabel/Select"
import Delete from "src/components/Modal/Delete"
import Button from "src/components/MyButton/Button"
import TableCustom from "src/components/Table/CustomTable"
import { renderButtonCircle } from "src/lib/utils"
import RoleService from "src/services/RoleService"
import ModalRoleGroupForm from "./ModalRoleGroupForm"

import FlInput from "src/components/FloatingLabel/Input"
import { RoleStyled } from "./styled"
import Notice from "src/components/Notice"
import SpinFC from "antd/es/spin"
import SpinCustom from "src/components/Spin"

const Role = ({ showEdit }) => {
  const [loading, setLoading] = useState(false)
  const [isEditRole, setIsEditRole] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const [total, setTotal] = useState(0)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [dataInfo, setDataInfo] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [infoDelete, setInfoDelete] = useState()
  const [TextSearch, setTextSearch] = useState("")
  const [status, setStatus] = useState(2)
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_number: 1,
  })

  const getListRole = async value => {
    setLoading(true)

    try {
      const res = await RoleService.getListRole({
        ...value,
      })
      if (res.isOk) {
        setDataSource(res?.data)
        setTotal(res?.total)
      }
    } finally {
      setLoading(false)
    }
  }

  const deleteRole = async value => {
    setLoading(true)

    try {
      // const values = await form.validateFields();
      const res = await RoleService.deleteRoleGroupByID({
        role_group_id: value.id,
      })
      if (res.isOk) {
        getListRole()
        Notice({
          isSuccess: true,
          msg: `Xóa thành công `,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getListRole({
      ...pagination,
      status: status,
    })
  }, [status])

  const onSearch = value =>
    getListRole({
      ...pagination,
      ...(value !== null && value !== "" ? { role_name: value } : {}),
    })
  const onstatus = status => setStatus(status)

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 60,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên nhóm quyền",
      dataIndex: "role_name",
      key: "role_name",
      width: 200,
    },
    {
      title: "Quyền nguyên tố",
      dataIndex: "role_categories",
      key: "role_categories",
      render: (val, record) => (
        <>
          <div className="fw-600">{record.role_name}:</div>

          <ul>
            {val?.map(i => (
              <Tooltip
                mouseEnterDelay={0.5}
                title={`${i.description}:
              ${i.role_categories?.map(data => data.description)}`}
                key={`tip${i.id}`}
              >
                <li
                  key={`${i?.id}${i?.description}`}
                  className="text-ellipsis text-ellipsis-1"
                >
                  {!!i.children ? (
                    <>
                      {`• ${i.description}:
                   ${i.children?.map(data => " " + data.description)}`}
                    </>
                  ) : (
                    <>{`• ${i.description}:`}</>
                  )}
                </li>
              </Tooltip>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "is_delete",
      key: "               ",
      align: "center",
      width: 200,
      render: (val, record) => (
        <div>
          <div>
            {record.status == 0 && (
              <div className="fw-600" style={{ color: "red" }}>
                Không hoạt động
              </div>
            )}
            {record.status == 1 && (
              <div className="fw-600" style={{ color: "green" }}>
                Đang hoạt động
              </div>
            )}
            {/* {val ? (
              <div className="fw-600" style={{ color: "" }}>
                Không hoạt động
              </div>
            ) : (
              <div className="fw-600" style={{ color: "#0D99FF" }}>
                Đang hoạt động
              </div>
            )} */}
          </div>
          {!showEdit && (
            <FloatActionWrapper size="small" className="float-action__wrapper">
              {renderButtonCircle(
                "Chỉnh sửa",
                "edit",
                () => {
                  setIsEditRole(true)
                  setDataInfo(record)
                  // setOpenModalEdit(true)
                  setOpenEditModal(record)
                },
                true,
              )}
              {/* {renderButtonCircle(
                "Log record",
                "edit",
                () => {
                 console.log("record",record)
                },
                true,
              )} */}
              {/* {renderButtonCircle(
              "Lịch sử hoạt động",
              "history",
              () => {
                setIsEditRole(true)
                setDataInfo(record)
                setOpenModalEdit(true)
              },
              true,
            )} */}
              {renderButtonCircle(
                "Xoá",
                "bin",
                () => {
                  setOpenDeleteModal(record)
                  setInfoDelete(record)
                },
                true,
              )}
            </FloatActionWrapper>
          )}
        </div>
      ),
    },
  ]

  return (
    <RoleStyled>
      {/* <Spin spinning={loading}> */}
      {!showEdit && (
        <Row className="box-action" gutter={16}>
          <Col span={18}>
            <FlInput
              search="true"
              allowClear
              label="Tên nhóm quyền"
              enterButton
              value={TextSearch}
              onChange={val => setTextSearch(val.target.value)}
              onSearch={onSearch}
            />
          </Col>
          <Col span={6}>
            <FlSelect
              style={{ width: "100%" }}
              onChange={status => onstatus(status)}
              value={status}
              maxTagCount="responsive"
              label="Trạng thái"
            >
              <Select.Option value={2} key={2}>
                Tất cả
              </Select.Option>
              <Select.Option value={1} key={1}>
                Đang hoạt động
              </Select.Option>
              <Select.Option value={0} key={0}>
                Không hoạt động
              </Select.Option>
            </FlSelect>
          </Col>
        </Row>
      )}
      <Divider />
      <div className="title-type-1  d-flex justify-content-space-between align-items-center">
        <span className="fs-24">
          {showEdit ? "Danh sách nhóm quyền" : "Phân quyền"}
        </span>
        {!showEdit && (
          <Button
            btnType="primary"
            className="btn-hover-shadow"
            onClick={() => setOpenModalEdit(true)}
          >
            Thêm nhóm quyền
          </Button>
        )}
      </div>
      <TableCustom
        loading={loading}
        isPrimary
        columns={columns}
        dataSource={dataSource}
        sticky={{ offsetHeader: -14 }}
        scroll={{ x: "800px" }}
        style={{ marginBottom: 4 }}
        pagination={{
          hideOnSinglePage: total <= 10,
          current: pagination.page_number,
          pageSize: pagination.page_size,
          responsive: true,
          total,
          locale: { items_per_page: "" },
          showSizeChanger: total > 10,
          onChange: (page, pageSize) => {
            setPagination({
              ...pagination,
              page_number: page,
              page_size: pageSize,
            })
            getListRole({
              ...pagination,
              page_number: page,
              page_size: pageSize,
            })
          },
        }}
        showPagination
        rowKey={record => `${record?.roleId}${record?.LastUpdate}`}
        footerLeft={<div className="d-flex mt-20" />}
        widthScroll={1200}
        textEmpty="Không có Nhóm quyền nào!"
      />

      {openDeleteModal && (
        <Delete
          isIcon={true}
          isTitle="Xác nhận xóa"
          isOpen={!!openDeleteModal}
          content={{
            title: (
              <div className="fw-600 text-center" style={{ fontSize: 16 }}>
                Bạn có chắc chắn muốn xóa nhóm quyền "
                <span style={{ color: "red", fontWeight: 600 }}>
                  {openDeleteModal?.description}
                </span>
                " không?
              </div>
            ),
          }}
          onOk={() => {
            deleteRole(infoDelete)
            setOpenDeleteModal(false)
          }}
          onCancel={() => setOpenDeleteModal(false)}
        />
      )}

      {openModalEdit && (
        <ModalRoleGroupForm
          // edit={true}
          isEditRole={isEditRole}
          visible={openModalEdit}
          dataInfo={dataInfo}
          onOk={() => {
            getListRole()
            setDataInfo({})
            setOpenModalEdit(false)
          }}
          onCancel={() => {
            // CB1({
            //   title: ` Dữ liệu chưa được lưu. Bạn có chắc vẫn muốn thoát không?`,
            //   icon: "warning-usb",
            //   okText: "Đồng ý",
            //   onOk: async close => {
            setDataInfo({})
            setOpenModalEdit(false)
            //     close()
            //   },
            // })
          }}
        />
      )}
      {openEditModal && (
        <ModalRoleGroupForm
          edit={true}
          isEditRole={openEditModal}
          visible={openEditModal}
          dataInfo={dataInfo}
          onOk={() => {
            getListRole()
            setDataInfo({})
            setOpenEditModal(false)
          }}
          onCancel={() => {
            // CB1({
            //   title: ` Dữ liệu chưa được lưu. Bạn có chắc vẫn muốn thoát không?`,
            //   icon: "warning-usb",
            //   okText: "Đồng ý",
            //   onOk: async close => {
            setDataInfo({})
            setOpenEditModal(false)
            //     close()
            //   },
            // })
          }}
        />
      )}
      {/* </Spin> */}
    </RoleStyled>
  )
}
export default Role
