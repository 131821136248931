import {
  apiUploadFile,
} from "./urls"
import http from "../index"

const uploadFile = body => http.post(apiUploadFile, body)


const FileService = { uploadFile, }
export default FileService
