import { InboxOutlined, UserOutlined } from "@ant-design/icons"
import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Spin,
  Upload,
  message,
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SvgIcon from "src/components/SvgIcon"
import STORAGE, { getStorage } from "src/lib/storage"
import { normFile } from "src/lib/utils"
import FileService from "src/services/FileService"
import PractiseService from "src/services/PractiseService"
import SunEditor from "suneditor-react"
import "suneditor/dist/css/suneditor.min.css" // Import Sun Editor's CSS File
import { UploadStyle } from "../../styled"
import styled from "styled-components"
const LargeIcon = styled(InboxOutlined)`
  font-size: 48px;
  width: 48px;
  height: 48px;
`
const { RangePicker } = DatePicker

const TabOpening = ({ Opening, next, data }) => {
  const [avatarUpload, setAvatarUpload] = useState("")
  const [bannerUpload, setBannerUpload] = useState("")
  const [fileUpload, setFileUpload] = useState([])
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { id } = useParams()
  const userInfo = getStorage(STORAGE.USER_INFO)
  const [valueDes, setValueDes] = useState()
  // Hàm chuyển đổi chuỗi ngày giờ thành đối tượng Date
  const parseDateString = dateString => {
    const [time, date] = dateString.split(", ")
    const [hours, minutes] = time.split(":").map(Number)
    const [day, month, year] = date.split("/").map(Number)

    // Tạo đối tượng Date
    return new Date(year, month - 1, day, hours, minutes)
  }

  const getData = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getBannerInfo({
        option: Opening ? 1 : 0,
        practise_id: id,
      })
      if (!!resp.isOk && !!resp?.isdata) {
        form.setFieldsValue({
          ...resp?.data,
          date: [
            !!resp.data.start_date ? dayjs(resp.data.start_date) : undefined,
            !!resp.data.end_date ? dayjs(resp.data.end_date) : undefined,
          ],
          description: resp?.data?.description,
          listselect: !!resp?.data?.listselect
            ? !!resp?.data?.listselect.length > 0
              ? resp?.data?.listselect[0]
              : undefined
            : undefined,
        })
        setAvatarUpload({
          url: Opening
            ? resp?.data?.logo_organization_url
            : resp?.data?.banner_close_url,
        })
        setBannerUpload({ url: resp?.data?.banner_start_url })
        if (!!resp?.data?.files) {
          setFileUpload(
            resp?.data?.files?.map((item, index) => ({
              ...item,
              uid: item?.id || index + 1,
              name: !!item?.title ? item?.title : "name.png",
              status: "done",
              url: item?.url,
            })),
          )
        }
        setValueDes(resp?.data?.description)
      } else {
        console.log(data)
        form.setFieldsValue({
          ...data,
          date: [
            !!data?.start_date
              ? dayjs(parseDateString(data?.start_date))
              : undefined,
            !!data?.end_date
              ? dayjs(parseDateString(data?.end_date))
              : undefined,
          ],
        })
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [Opening])
  const createForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      console.log(fileUpload)
      const formattedFiles =
        !!fileUpload &&
        fileUpload.map(file => ({
          id: file.id,
          title: file.name,
          url: file.url,
          size: file.size,
          updatedAt: file.updatedAt,
          createdAt: file.createdAt,
        }))
      // Kiểm tra nếu đang chỉnh sửa thì sử dụng updatePractiseByID, ngược lại sử dụng createPractise
      // Tạo body request
      const requestBody = {
        ...values,
        option: Opening ? 1 : 0,
        practise_id: id,
        user_id: userInfo?.id,
        banner_start_url: Opening ? bannerUpload.url : undefined,
        logo_organization_url: Opening ? avatarUpload.url : undefined,
        banner_close_url: Opening ? undefined : avatarUpload.url,
        files: !!formattedFiles ? formattedFiles : undefined,

        start_date: Opening
          ? !!values?.date
            ? values.date[0].format("YYYY-MM-DD HH:mm")
            : undefined
          : undefined,
        end_date: Opening
          ? !!values?.date
            ? values.date[1].format("YYYY-MM-DD HH:mm")
            : undefined
          : undefined,
      }
      // console.log(fileUpload)
      const res = await PractiseService.addBannerInfo(requestBody)
      if (res.isError) return
      if (res.isOk) {
        getData()
        if (Opening) {
          next()
        }
        Notice({
          isSuccess: true,
          msg: Opening ? "Tạo khai mạc thành công" : "Tạo bế mạc thành công",
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const uploadImg = async (file, event) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("file_list", file)
      formData.append("practise_id", id)
      const res = await FileService.uploadFile(formData)
      if (res.isError) return
      const newFile = res.data[0]
      const formattedFile = {
        uid: newFile.id || file.uid,
        name: newFile.title || file.name,
        status: "done",
        url: newFile.url,
        size: file.size,
        id: newFile.id,
        title: newFile.title || file.name,
        updatedAt: newFile.updatedAt || new Date().toISOString(),
        createdAt: newFile.createdAt || new Date().toISOString(),
      }
      switch (event) {
        case 1:
          setAvatarUpload(newFile)
          break
        case 2:
          setBannerUpload(newFile)
          break
        case 3:
          setFileUpload(prevState => [...prevState, formattedFile])
          break
        default:
          break
      }
    } finally {
      setLoading(false)
    }
  }
  const disabledDate = current => {
    // Không cho phép chọn các ngày trong quá khứ (so với ngày hiện tại)
    return current && current < Date.now()
  }
  const props = {
    name: "file_list",
    defaultFileList: fileUpload,
    multiple: true,
    customRequest({ file, onSuccess, onError }) {
      console.log(fileUpload, file)
      const isDuplicate = fileUpload.some(item => item.name === file.name)
      if (isDuplicate) {
        message.error(`Tên file "${file.name}" đã tồn tại trong danh sách!`)
        onError(
          new Error(`Tên file "${file.name}" đã tồn tại trong danh sách!`),
        )

        return
      }
      uploadImg(file, 3)
        .then(() => {
          onSuccess(null, file)
        })
        .catch(onError)
    },
    onChange(info) {
      const { status } = info.file
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        console.log(fileUpload)
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onRemove(file) {
      const listArray = fileUpload.filter(item => item.name !== file.name)
      setFileUpload(listArray)
      console.log("check delete fiel:", fileUpload, file, listArray)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    },
  }
  const avatar = {
    onRemove(file) {
      setAvatarUpload(undefined)
      console.log(file, avatarUpload)
    },
  }
  const banner = {
    onRemove(file) {
      setBannerUpload(undefined)
      console.log(file, bannerUpload)
    },
  }
  return (
    <div>
      <Form form={form} layout="vertical" scrollToFirstError>
        <Spin spinning={loading}>
          <Row gutter={[14, 2]} className="mr-20">
            {!Opening && (
              <Col xs={12}>
                <Row gutter={[10]} className="div-avatar">
                  <Col span={24}>
                    <h3>Banner bế mạc</h3>
                  </Col>
                  <Col span={16} className="wrap-avatar">
                    {!!avatarUpload ? (
                      <div>
                        <img
                          style={{ height: "170px", width: "100%" }}
                          className="user-avatar"
                          src={avatarUpload?.url}
                          alt="avatar"
                        />
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center user-avatar"
                        style={{ backgroundColor: "#ddd" }}
                      >
                        <UserOutlined
                          style={{
                            fontSize: "40px",
                            color: "#fff",
                            height: "170px",
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col span={8} className="boxAccount">
                    <Form.Item
                      // label="Logo đơn vị tổ chức /đồng tổ chức"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      name="Avatar"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!!value?.find(i => i?.size > 5 * 1024 * 1024)) {
                              return Promise.reject(
                                new Error("Dung lượng file tối đa 5MB"),
                              )
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Upload
                        {...avatar}
                        beforeUpload={file => {
                          uploadImg(file, 1)
                          return false
                        }}
                        accept="image/*"
                        multiple={false}
                        maxCount={1}
                        fileList={[]}
                      >
                        <div className="upload-avatar">
                          <div className="d-flex justify-content-center">
                            <Button className="sign-button-upload ">
                              <div className="sign-background-upload d-flex align-items-center">
                                <SvgIcon name="small-image-red" />
                                <div className="sign-text-upload ml-16">
                                  Chọn ảnh
                                </div>
                              </div>
                            </Button>
                          </div>
                        </div>
                        <div
                          className="sign-text"
                          onClick={e => {
                            e.stopPropagation()
                          }}
                        >
                          <div>Dung lượng file tối đa 5MB</div>
                          <div>Định dạng:.JPG, .JPEG, .PNG, .SVG</div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            {Opening ? (
              <Col span={24}>
                <Row gutter={[14, 2]}>
                  <Col xs={12}>
                    <Row gutter={[10]} className="div-avatar">
                      <Col span={24}>
                        <h3>Logo đơn vị tổ chức /đồng tổ chức</h3>
                      </Col>
                      <Col span={16} className="wrap-avatar">
                        {!!avatarUpload ? (
                          <img
                            style={{ height: "170px", maxWidth: "100%" }}
                            className="user-avatar"
                            src={avatarUpload?.url}
                            alt="avatar"
                          />
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center user-avatar"
                            style={{ backgroundColor: "#ddd" }}
                          >
                            <UserOutlined
                              style={{
                                fontSize: "40px",
                                color: "#fff",
                                height: "170px",
                              }}
                            />
                          </div>
                        )}
                      </Col>
                      <Col span={8} className="boxAccount">
                        <Form.Item
                          // label="Logo đơn vị tổ chức /đồng tổ chức"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          name="Avatar"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (
                                  !!value?.find(i => i?.size > 5 * 1024 * 1024)
                                ) {
                                  return Promise.reject(
                                    new Error("Dung lượng file tối đa 5MB"),
                                  )
                                }
                                return Promise.resolve()
                              },
                            }),
                          ]}
                        >
                          <Upload
                            {...avatar}
                            beforeUpload={file => {
                              uploadImg(file, 1)
                              return false
                            }}
                            accept="image/*"
                            multiple={false}
                            maxCount={1}
                            fileList={[]}
                          >
                            <div className="upload-avatar">
                              <div className="d-flex justify-content-center">
                                <Button className="sign-button-upload ">
                                  <div className="sign-background-upload d-flex align-items-center">
                                    <SvgIcon name="small-image-red" />
                                    <div className="sign-text-upload ml-16">
                                      Chọn ảnh
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            </div>
                            <div
                              className="sign-text"
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <div>Dung lượng file tối đa 5MB</div>
                              <div>Định dạng:.JPG, .JPEG, .PNG, .SVG</div>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[10]} className="div-avatar">
                      <Col span={24}>
                        <h3>Banner khai mạc</h3>
                      </Col>
                      <Col span={16} className="wrap-avatar">
                        {!!bannerUpload ? (
                          <img
                            style={{ height: "170px", maxWidth: "100%" }}
                            className="user-avatar"
                            src={bannerUpload?.url}
                            alt="avatar"
                          />
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center user-avatar"
                            style={{ backgroundColor: "#ddd" }}
                          >
                            <UserOutlined
                              style={{
                                fontSize: "40px",
                                color: "#fff",
                                height: "170px",
                              }}
                            />
                          </div>
                        )}
                      </Col>
                      <Col span={8} className="boxAccount">
                        <Form.Item
                          // label="Banner khai mạc"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          name="banner"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (
                                  !!value?.find(i => i?.size > 5 * 1024 * 1024)
                                ) {
                                  return Promise.reject(
                                    new Error("Dung lượng file tối đa 5MB"),
                                  )
                                }
                                return Promise.resolve()
                              },
                            }),
                          ]}
                        >
                          <Upload
                            {...banner}
                            beforeUpload={file => {
                              uploadImg(file, 2)
                              return false
                            }}
                            accept="image/*"
                            multiple={false}
                            maxCount={1}
                            fileList={[]}
                          >
                            <div className="upload-avatar">
                              <div className="d-flex justify-content-center">
                                <Button className="sign-button-upload ">
                                  <div className="sign-background-upload d-flex align-items-center">
                                    <SvgIcon name="small-image-red" />
                                    <div className="sign-text-upload ml-16">
                                      Chọn ảnh
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            </div>
                            <div
                              className="sign-text"
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <div>Dung lượng file tối đa 5MB</div>
                              <div>Định dạng:.JPG, .JPEG, .PNG, .SVG</div>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Tên diễn tập"
                      name="practise_name"
                      rules={[
                        {
                          required: true,
                          message: "Tên diễn tâp không được để trống",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Thời gian diễn ra" name="date">
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        disabledDate={disabledDate}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Link meeting"
                      name="link_meeting"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Link meeting không được để trống",
                      //   },
                      // ]}
                    >
                      <Input allowClear placeholder="Nhập" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={24}>
                    <Form.Item
                      label="Địa điểm diễn tập"
                      name="practise_address"
                      rules={[
                        {
                          required: true,
                          message: "Địa điểm diễn tập không được để trống",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập" />
                    </Form.Item>
                  </Col> */}
                </Row>
              </Col>
            ) : (
              <Col span={24}>
                <Form.Item
                  label="Thông tin công khai"
                  name="listselect"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={"Không công khai"}>Không công khai</Radio>
                    <Radio value={"Công khai chi tiết bảng điểm"}>
                      Công khai chi tiết bảng điểm
                    </Radio>
                    <Radio value={"Chỉ công khai tổng điểm và bảng xếp hạng"}>
                      Chỉ công khai tổng điểm và bảng xếp hạng
                    </Radio>
                    <Radio value={"Công khai số lượng và chi tiết lỗi"}>
                      Công khai số lượng và chi tiết lỗi
                    </Radio>
                    <Radio value={"Công khai số lượng lỗi"}>
                      Công khai số lượng lỗi
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
            {!!fileUpload?.length > 0 && (
              <Col span={24}>
                <UploadStyle>
                  <Form.Item
                    label="Upload tài liệu"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    name="docs"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!!value?.find(i => i?.size > 5 * 1024 * 1024)) {
                            return Promise.reject(
                              new Error("Dung lượng file tối đa 5MB"),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Upload.Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <LargeIcon />
                      </p>
                      <p className="ant-upload-text">
                        Kéo thả file đính kèm hoặc Chọn File
                      </p>
                      <span
                        className="fs-12 "
                        style={{
                          textDecoration: "underline",
                          color: "#2747A0",
                        }}
                      >
                        Tải lên file
                      </span>
                    </Upload.Dragger>
                  </Form.Item>
                </UploadStyle>
              </Col>
            )}
            {!fileUpload?.length > 0 && (
              <Col span={24}>
                <UploadStyle>
                  <Form.Item
                    label="Upload tài liệu"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    name="docs"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!!value?.find(i => i?.size > 5 * 1024 * 1024)) {
                            return Promise.reject(
                              new Error("Dung lượng file tối đa 5MB"),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Upload.Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <LargeIcon />
                      </p>
                      <p className="ant-upload-text">
                        Kéo thả file đính kèm hoặc Chọn File
                      </p>
                      <span
                        className="fs-12 "
                        style={{
                          textDecoration: "underline",
                          color: "#2747A0",
                        }}
                      >
                        Tải lên file
                      </span>
                    </Upload.Dragger>
                  </Form.Item>
                </UploadStyle>
              </Col>
            )}
            {/* <Col span={24}>
              {!!valueDes && (
                <Form.Item label="Mô tả Thêm" name="description">
                  <SunEditor height="200px" defaultValue={valueDes} />
                </Form.Item>
              )}
              {!valueDes && (
                <Form.Item label="Mô tả Thêm" name="description">
                  <SunEditor height="200px" />
                </Form.Item>
              )}
            </Col> */}
            <Col span={24} className="mb-40">
              <Button
                type="primary"
                onClick={() => createForm()}
                htmlType="submit"
              >
                {Opening ? "Lưu và Khai mạc" : "Lưu và kết thúc"}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </div>
  )
}

export default TabOpening
