import { Col, Modal, Row, Space, Steps, message, theme } from "antd"
import React, { useState } from "react"
import Button from "src/components/MyButton/Button"
import FormInfo from "./Components/FormInfo"
import UserJoin from "./Components/UserJoin"
import Document from "./Components/Document"
import Role from "../../Role"
import CustomModal from "src/components/Modal/CustomModal"

const NewRehearsal = ({ open, onOk, onCancel, edit, practise_id, getData }) => {
  return (
    <CustomModal
      title={edit ? "Chỉnh sửa cuộc diễn tập" : "Thêm cuộc diễn tập"}
      width="90%"
      footer={null}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
    >
      <FormInfo
        onCancel={onCancel}
        edit={edit}
        practise_id={practise_id}
        getData={getData}
      />
    </CustomModal>
  )
}

export default NewRehearsal
