import React, { useState, useEffect } from "react"
import { Select, Form, Col, Spin, Row } from "antd"
import RegionService from "src/services/RegionService"
// Adjust the import based on your file structure

const RegionSelect = ({ valueCity, valueDistrict, valueWards }) => {
  const [listProvince, setListProvince] = useState([])
  const [listDistrict, setListDistrict] = useState([])
  const [listWards, setListWards] = useState([])
  const [loading, setLoading] = useState(false)

  console.log(valueCity, valueDistrict, valueWards)
  const getProvince = async () => {
    try {
      setLoading(true)
      const resp = await RegionService.getListProvince()
      if (resp.isOk) {
        const ls = resp.data.map(item => ({
          value: item?.id,
          label: item?.region_name,
        }))
        setListProvince(ls)
      }
    } finally {
      setLoading(false)
    }
  }

  const getDistrict = async provinceId => {
    try {
      setLoading(true)
      const resp = await RegionService.getListDistrict({
        province_id: provinceId,
      })
      if (resp.isOk) {
        const ls = resp.data.map(item => ({
          value: item?.id,
          label: item?.region_name,
        }))
        setListDistrict(ls)
      }
    } finally {
      setLoading(false)
    }
  }

  const getWards = async districtId => {
    try {
      setLoading(true)
      const resp = await RegionService.getListwards({
        district_id: districtId,
      })
      if (resp.isOk) {
        const ls = resp.data.map(item => ({
          value: item?.id,
          label: item?.region_name,
        }))
        setListWards(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getProvince()
    if (!!valueCity) {
      getDistrict(valueCity)
    }
    if (!!valueDistrict) {
      getWards(valueDistrict)
    }
  }, [valueCity, valueDistrict, valueWards])

  return (
    <Spin spinning={loading}>
      <Row gutter={[20]}>
        <Col span={8}>
          <Form.Item
            label="Tỉnh/Thành phố"
            name="city"
            rules={[
              {
                required: true,
                message: "Tỉnh/Thành phố không được để trống",
              },
            ]}
          >
            <Select allowClear options={listProvince} onChange={getDistrict} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Quận/Huyện"
            name="district"
            rules={[
              {
                required: true,
                message: "Quận/Huyện không được để trống",
              },
            ]}
          >
            <Select allowClear options={listDistrict} onChange={getWards} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Xã/Phường" name="wards">
            <Select allowClear options={listWards} />
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  )
}

export default RegionSelect
