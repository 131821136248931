import React from 'react';
import { IConCustomTonggerStyle } from '../../../styled';
import SvgIcon from 'src/components/SvgIcon';

const IConCustomTongger = ({ nameSvg, iconColor, backgroundColor, nameToolTip, backgroundColorToolTip, backgroundbefore }) => {
    return (
        <IConCustomTonggerStyle>
            <li className="icon twitter" style={{ backgroundColor: backgroundColor }}>
                <span className="tooltip" style={{ backgroundColor: backgroundColorToolTip, '--tooltip-before-bg-color': backgroundbefore }}>{nameToolTip}</span>
                <SvgIcon name={nameSvg} style={{ color: iconColor}} />
            </li>
        </IConCustomTonggerStyle>
    );
}

export default IConCustomTongger;