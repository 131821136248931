// ReusableEditor.js
import React from "react"
import SunEditor from "suneditor-react"
import "suneditor/dist/css/suneditor.min.css"
import { StyleSunEditor } from "./styled"

const ReusableEditor = ({ value, onChange }) => {
  const defaultOptions = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      "/", // Line break
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print"],
      ["save"],
    ],
    height: 150, // You can adjust the height as needed
  }

  return (
    <StyleSunEditor>
      <SunEditor
        setOptions={defaultOptions}
        setContents={value}
        onChange={onChange}
      />
    </StyleSunEditor>
  )
}

export default ReusableEditor
