import {
  apiGetActivities,
  apiStartPractise,
  apiClosePractise,
  apiGetErrorLog,
} from "./urls"
import http from "../index"
import QueryString from "qs"

const GetActivities = body =>
  http.get(apiGetActivities + `?${QueryString.stringify(body)}`)
const PostStartPractise = body => http.post(apiStartPractise, body)
const PostClosePractise = body => http.post(apiClosePractise, body)
const getErrorLog = params => http.get(apiGetErrorLog, { params })

const ActivityService = {
  GetActivities,
  PostStartPractise,
  PostClosePractise,
  getErrorLog,
}
export default ActivityService
