import { Col, DatePicker, Form, Input, Modal, Row, Select, Space } from "antd"
import React, { useEffect, useState } from "react"
import Button from "src/components/MyButton/Button"
import { dataEditor, itemsCollapse } from "../../tagData"
import { StyleModalReport } from "./styled"
import { EditorData } from "src/lib/EditorData"
import FormService from "src/services/FormSevice"
import {
  getRegexEmail,
  getRegexMobile,
  validateEmail,
} from "src/lib/stringsUtils"
import SunEditor from "suneditor-react"
import ReusableEditor from "src/components/ReusableEditor"

const ModalReport = ({ open, onOk, onCancel, getData }) => {
  const { RangePicker } = DatePicker
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [listError, setListError] = useState()
  const [listlevel, setListlevel] = useState()
  const getListlevel = async () => {
    try {
      setLoading(true)
      const res = await FormService.getLevelError()
      if (res.isOk) {
        let ls = res.data.map((item, index) => {
          return {
            value: item?.id || index + 1,
            label: item?.value,
          }
        })
        setListlevel(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const getListError = async () => {
    try {
      setLoading(true)
      const res = await FormService.getListError()
      if (res.isOk) {
        let ls = res.data.map((item, index) => {
          return {
            value: item?.id || index + 1,
            label: item?.value,
          }
        })
        setListError(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListError()
    getListlevel()
  }, [])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const values = await form?.validateFields()
      const requestBody = {
        ...values,
        practise_id: open?.practise_id,
        user_id: open?.user_id,
        start_date: !!values?.date
          ? values.date[0].format("YYYY-MM-DD HH:mm")
          : undefined,
        end_date: !!values?.date
          ? values.date[1].format("YYYY-MM-DD HH:mm")
          : undefined,
        cur_date: !!values?.cur_date
          ? values.cur_date.format("YYYY-MM-DD HH:mm")
          : undefined,
      }
      const res =
        open?.status === 1
          ? await FormService.PostForm3(requestBody)
          : await FormService.PostForm4(requestBody)
      if (res.isOk) {
        onCancel()
        getData()
        // getDetailsForm()
        // next()
        // window.open(res.data, "_blank")
        // setPDF(res.data)
      } else {
        // Notice({ isSuccess: true, msg: res.message })
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <Modal
        title={
          open.status === 1 ? "Báo cáo đội tấn công" : "Báo cáo đội phòng thủ"
        }
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              // onClick={() => createForm()}
            >
              Xem trước PDF
            </Button>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              // onClick={() => createForm()}
            >
              Tải xuống
            </Button>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              // onClick={() => createForm()}
            >
              Lưu nháp
            </Button>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Gửi báo cáo
            </Button>
          </Space>
        }
      >
        <StyleModalReport>
          <Form form={form} layout="vertical" className="mt-20">
            <Row gutter={[14, 10]} className="mr-20 ">
              {/* <Col span={24}>
                <h3>THÔNG TIN VỀ TỔ CHỨC/CÁ NHÂN BÁO CÁO</h3>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="1. Tên tổ chức/cá nhân báo cáo"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Tên tổ chức/cá nhân không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="2. Địa chỉ"
                  name="addr"
                  rules={[
                    {
                      required: true,
                      message: "Địa chỉ không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="3. Điện thoại"
                  name="phone"
                  rules={[
                    {
                      pattern: getRegexMobile(),
                      message:
                        "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                    },
                    {
                      required: true,
                      message: "Điện thoại không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="4. Email"
                  name="email"
                  rules={[
                    {
                      pattern: getRegexEmail(),
                      message: "Email nhập sai định dạng!",
                    },
                    {
                      required: true,
                      message: "Email không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col> */}
              {/* <Col span={24}>
                <h3>NGƯỜI LIÊN HỆ</h3>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="1. Họ và tên"
                  name="contact_name"
                  rules={[
                    {
                      required: true,
                      message: "Họ và tên không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="2. Chức vụ" name="contact_pos">
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="3. Điện thoại"
                  name="contact_phone"
                  rules={[
                    {
                      pattern: getRegexMobile(),
                      message:
                        "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                    },
                    {
                      required: true,
                      message: "Điện thoại không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="4. Email"
                  name="contact_email"
                  rules={[
                    {
                      pattern: getRegexEmail(),
                      message: "Email nhập sai định dạng!",
                    },
                    {
                      required: true,
                      message: "Email không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="5. Thời gian thực hiện diễn tập"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Thời gian không được để trống",
                    },
                  ]}
                >
                  <RangePicker showTime />
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <h3>KẾT QUẢ {open.status === 1 ? "TẤN CÔNG" : "PHÒNG THỦ"} </h3>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="1. Mô tả"
                  name="describe"
                  rules={[
                    {
                      required: true,
                      message: "Mô tả không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="2. Loại lỗi phát hiện" name="type_error">
                  {console.log(listError)}
                  <Select allowClear options={listError} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="3. Mức độ" name="level">
                  {/* <Input allowClear placeholder="Nhập" /> */}
                  <Select
                    allowClear
                    // defaultValue="lucy"
                    // style={{
                    //   width: 120,
                    // }}
                    // onChange={handleChange}
                    options={listlevel}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="4. URL xuất hiện lỗi"
                  name="url_error"
                  rules={[
                    {
                      required: true,
                      message: "URL không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="5. Tác động"
                  name="impact"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <ReusableEditor />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="6. Poc"
                  name="poc"
                  rules={[
                    {
                      required: true,
                      message: "Poc không được để trống",
                    },
                  ]}
                >
                  <ReusableEditor />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h3>KIẾN NGHỊ, ĐỀ XUẤT HỖ TRỢ</h3>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={`Đề nghị cung cấp tóm lược về các kiến nghị và đề xuất hỗ trợ
                  trong tổ chức thực hiện ${
                    open.status === 1 ? "tấn công" : "phòng thủ"
                  }`}
                  name="propose"
                >
                  <ReusableEditor />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Thời gian thực hiện báo cáo"
                  name="cur_date"
                  rules={[
                    {
                      required: true,
                      message: "Thời gian không được để trống",
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    onChange={(value, dateString) => {
                      console.log("Selected Time: ", value)
                      console.log("Formatted Selected Time: ", dateString)
                    }}
                    // onOk={onOk}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h3>
                  NGƯỜI ĐẠI DIỆN ĐỘI{" "}
                  {open.status === 1 ? "TẤN CÔNG" : "PHÒNG THỦ"}
                </h3>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    btnType="primary"
                    className="btn-hover-shadow"
                    type="submit"
                    // onClick={() => createForm()}
                  >
                    Ký tên
                  </Button>
                  <Button
                    btnType="primary"
                    className="btn-hover-shadow"
                    type="submit"
                    // onClick={() => createForm()}
                  >
                    Đóng dấu
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </StyleModalReport>
      </Modal>
    </>
  )
}

export default ModalReport
