import styled from "styled-components"

export const StyleModalReport = styled.div`
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 100px !important;
    padding: 5px;
  }
`
export const StyleDetails = styled.div`
  .title-name {
    font-weight: 700;
    margin-right: 10px;
  }
  .title-text {
    text-transform: uppercase;
    color: #154398;
  }
  .box {
    padding: 10px 20px 10px 20px;
    background: #f7f7f7;
    border-radius: 10px;
  }
`
